import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import Facebook from 'components/SEO/Facebook'

function SEO({ description, lang, keywords, title, pathname }) {
  return (
    <StaticQuery
      // eslint-disable-next-line no-use-before-define
      query={detailsQuery}
      render={(data) => {
        const siteData = data.site.siteMetadata
        const metaDescription = description || siteData.description
        const metaKeywords = keywords || siteData.keywords
        const metaImage = `${siteData.siteUrl}${siteData.image}`
        const metaUrl = `${siteData.siteUrl}${pathname}`
        return (
          <>
            <Helmet
              htmlAttributes={{
                lang,
              }}
              title={title}
              titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            >
              <meta name="keywords" content={metaKeywords} />
              <meta name="description" content={metaDescription} />
              <meta name="image" content={metaImage} />
              <meta name="url" content={metaUrl} />
            </Helmet>
            <Facebook
              description={metaDescription}
              image={metaImage}
              title={title}
              url={metaUrl}
              locale={siteData.ogLanguage}
              name={siteData.social.facebook}
            />
          </>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  pathname: ``,
  description: 'Page',
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  pathname: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        siteUrl
        description
        keywords
        author
        social {
          facebook
        }
        image
        ogLanguage
      }
    }
  }
`
